import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/dawn-of-the-dead-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1978 zombie movie, Dawn of the Dead"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 36</h1>
                    <h2>Dawn of the Dead</h2>
                    <h3>February 19, 2024</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> George Romero // <b>Starring:</b> Ken Foree &amp; Gaylen Ross</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/36-Dawn-of-the-Dead-e2fvgqn/a-aavrs7m" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe episode 36, Dawn of the Dead"></iframe>
                        </div>
                        {/* <p><Link to="/transcripts/my-bloody-valentine">Read the episode transcript</Link></p> */}
                        <p>Bryan and Dave celebrate one year of podcasting with a real deep dive into George Romero's paradigm shifting zombie epic, Dawn of the Dead. In the history of horror movies, there are only a few movies that changed the way movies are made and viewed and this is one of them. It's a zombie survival fantasy set in shopping mall with wild shifts in tone that don't always work and a graphic violence about it that its contemporaries did not share. After this one came out, everybody in Italy was suddenly making zombie horror movies.</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/night-of-the-creeps">Next Episode</Link></li>
                        <li><Link to="/episodes/my-bloody-valentine">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)